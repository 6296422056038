import type { QueryKey } from '@tanstack/react-query';
import { InternalQueryHookFactory } from 'utilities/methods/tanstack/createQuery/factories/hookFactory';
import { InternalQueryMethodFactory } from 'utilities/methods/tanstack/createQuery/factories/methodFactory';
import type { CreateQueryOptionsCallback } from 'utilities/methods/tanstack/createQuery/factories/types';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Meta = {
    error: any;
    data: any;
    variables: any;
};
export type TagResponse<TResult, TMeta extends Meta> = TResult & {
    /** Type of the errors based on historical artah types - can be used to type .catch errors */
    _error: TMeta['error'];
    
    /** Type of the data based on historical artah types - can be used to type return values */
    _data: TMeta['data'];
    
    /** Type of the variables based on historical artah types - can be used to type parameters */
    _variables: TMeta['variables'];
};

/**
 * General note for future iterations. If we want to optimize memory on this object, we can create variables using let
 * to store the functions in, then in getters, determine if the variable is undefined, and if so, run an initialize
 * function to create the hook and assign it.
 *
 * This will likely be an optimization we can do once we convert to a class internally, and are able to measure the memory difference
 */

/**
 * Second iteration of the `createQueryBoilerplate` function. This is properly typed and provides a minimal boilerplate
 * that can be used to create a query. For non-standard functionality, this can further be extended by using Object.assign on the resulting query
 * object prior to exporting.
 *
 * @example
 * ```ts
 * export const listTwoFactor = createQuery(() => ({
 *   queryFn: API.login.twoFactor.listMethods,
 *   queryKey: authenticationQueryKeys.listTwoFactor()
 * }));
 * ```
 *
 * @example
 * ```ts
 * const _query = createQuery((serviceId: number) => ({
 *   queryFn: () => API.example.myEndpoint(serviceId),
 *   queryKey: exampleQueryKeys.myEndpoint(params)
 * }));
 *
 * const invalidateAllServiceId = (serviceId: number) => queryClient.invalidateQueries({
 *   predicate: (query) => query.queryKey.includes(exampleQueryKeys.myEndpoint.all(params))
 * });
 *
 * export const myQuery = Object.assign(_query, {
 *   invalidate: invalidateAllServiceId
 * })
 * ```
 *
 * Note: if object assigning, consider whether you want to override the existing functionality, as providing
 * a value on the same key as an existing function will override it.
 */
// prettier-ignore
export const createNXQuery = <
    TParams = void,
    TQueryFnData extends NXQueryUtils.ApiData = NXQueryUtils.ApiData,
    TError = ErrorFromNXTaggedResponse<TQueryFnData>,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
>(createQueryOptionsCallback: CreateQueryOptionsCallback<TParams, TQueryFnData, TError, TData, TQueryKey>) => {
    const methods = new InternalQueryMethodFactory(createQueryOptionsCallback);
    const hooks = new InternalQueryHookFactory<TParams, TQueryFnData, TError, TData, TQueryKey>(methods.createQueryOptions);

    const result = {
        ...hooks,
        ...methods
    };

    /**********************************************************************************************************
     *   TYPE DEFINITIONS
     **********************************************************************************************************/
    // prettier-ignore
    type TaggedResponse = TagResponse<typeof result, {
        error: TError;
        data: TQueryFnData;
        variables: TParams;
    }>;

    return Object.assign(result as TaggedResponse, {
        addLazyMock: InternalQueryMethodFactory.createLazyMock(result as TaggedResponse)
    });
};
