import type { _AccountQuery } from 'containers/account/queries/queryTree';
import type { _billingQuery } from 'containers/billing/queries/queryTree';
import type { _domainQuery } from 'containers/domain/queries';
import type { _emailQuery } from 'containers/email/queries';
import type { _HostingQuery } from 'containers/hosting/queries';
import type { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaQueryV2 } from 'containers/katana/queries/v2/tanstackTree';
import type { _AuthQuery } from 'containers/login/queries/queryTree';
import type { _ms365Query } from 'containers/ms365/queries';
import type { _orderingFormQuery } from 'containers/orderingForm/queries';
import type { _ServicesQuery } from 'containers/services/queries/tanstackTree';
import type { _sslQuery } from 'containers/ssl/queries/queryTree';
import type { _ventraIpWordpressSupportCentreQuery } from 'utilities/api/ventraip-website-wp-support-centre/queries/tanstackTree';
import type { _useLazyQuery as useLazyQuery } from 'utilities/methods/queries/useLazyQuery';
import type { _usePopupMutation as usePopupMutation } from 'utilities/methods/queries/usePopupMutation';

/***** EXPORTS *****/
type NXQuery = {
    account: typeof _AccountQuery;
    billing: typeof _billingQuery;
    domain: typeof _domainQuery;
    ssl: typeof _sslQuery,
    auth: typeof _AuthQuery;
    email: typeof _emailQuery;
    hosting: typeof _HostingQuery;
    ms365: typeof _ms365Query;
    katana: typeof katanaQuery & {
        v2: typeof KatanaQueryV2;
    };
    services: typeof _ServicesQuery;
    supportCentre: typeof _ventraIpWordpressSupportCentreQuery;
    orderingForm: typeof _orderingFormQuery;
    Utils: {
        usePopupMutation: typeof usePopupMutation;
        useLazyQuery: typeof useLazyQuery;
    };
};

/**
 * Tanstack Query/Mutation object that should be used to build the router tree, and used within the application
 * instead of directly calling queries.
 */
export let NXQuery: NXQuery;

export const initializeNXQuery = (opts: NXQuery) => {
    NXQuery = Object.freeze(opts);
};
