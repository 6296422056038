/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useSearch } from '@tanstack/react-router';
import classNames from 'classnames';
import Anchor from 'components/Anchor';
import Avatar from 'components/Placeholders/Avatar';
import SolidTag from 'components/Tags/SolidTag';
import { Flex } from 'components/Utils/Flex';
import AppLogo from 'config/images/app.svg';
import { useLoginContext } from 'containers/login/consts';
import type React from 'react';
import { NXQuery } from 'utilities/query';
const backgrounds = [
    'linear-gradient(90deg, #0a9fe5, #10c5ef)',
    'linear-gradient(90deg, #5956d7, #c544fb)',
    'linear-gradient(90deg, #0dd41a, #86fc6f)',
    'linear-gradient(90deg, #c32888, #a01e54)',
    'linear-gradient(90deg, #f4ad26, #ca3c78)',
    'linear-gradient(90deg, #0575e6, #0054a8)',
    'linear-gradient(90deg, #f6d365, #fda085)',
    'linear-gradient(90deg, #84fab0, #8fd3f4)',
];

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type AccountSelect = React.FC<{
    resetLoginArea: () => void;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const AccountSelect: AccountSelect = ({ resetLoginArea }) => {
    /***** HOOKS *****/
    const navigate = useNavigate();
    const searchRef = useSearch({ strict: false, select: (search) => search.ref || 'dashboard' });
    const { setView } = useLoginContext();

    /***** QUERIES *****/
    const { data: accountList } = NXQuery.auth.accountList.useSelectSuspenseQuery(void 0, ({ data }) => data);
    const { mutateAsync: selectAccountAsync } = NXQuery.auth.selectAccount.useMutation();
    const { mutate: handleSelectAccount } = useMutation({
        onMutate: () => setView('loading'),
        async mutationFn(id: number) {
            await selectAccountAsync(id);

            await Promise.allSettled([
                // must force fetch since selectAccount updates our cookie
                NXQuery.auth.login.checkToken.forceFetch(),
                NXQuery.auth.userData.fetch(),
            ]);
        },
        onSuccess: () => navigate({ to: searchRef }),
        onError: () => setView('accountlist'),
    });

    /***** RENDER HELPERS *****/
    const getIsAccountHolder = (role: string) => role === 'account holder';
    const getTextContainerClasses = (company: string | null, role: string) =>
        classNames(`accountSelect__type--${company ? 'business' : 'personal'}`, {
            full: !getIsAccountHolder(role),
        });

    /***** RENDER *****/
    return (
        <Flex direction="column" justify="center" align="center" className="login__handler">
            <div className="login__header">
                <img src={AppLogo} alt="Customer Control Panel" />
            </div>
            <div className="accountSelect__title">Choose an account to log into below</div>
            <div className="accountSelect">
                {accountList?.map(({ attributes: { firstname, lastname, company, role }, id }, index) => (
                    <button key={index} className="accountSelect__account" onClick={() => handleSelectAccount(id)}>
                        <div className="accountSelect__container">
                            <div className="accountSelect__container--section">
                                <div className="accountSelect__initial">
                                    <Avatar fullname={`${firstname} ${lastname}`} size={45} fontSize={14} background={backgrounds[index]} />
                                </div>
                                <div className={getTextContainerClasses(company, role)}>
                                    <div className="accountSelect__company">{company}</div>
                                    <div className="accountSelect__name">{`${firstname} ${lastname}`}</div>
                                    {getIsAccountHolder(role) && <SolidTag>Your account</SolidTag>}
                                </div>
                            </div>
                        </div>
                    </button>
                ))}
            </div>
            <Anchor className="accountSelect__logout" onClick={resetLoginArea}>
                Logout
            </Anchor>
        </Flex>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default AccountSelect;
