/**
 * @fileoverview File that MUST be executed before the app is loaded. This
 * file contains initialization for primary utilities that are used everywhere
 * in the application, meaning that this MUST run before any reference to them.
 * 
 * This includes:
 *   - queryClient
 *   - NXQuery
 */
import { _AccountQuery } from "containers/account/queries/queryTree";
import { _billingQuery } from 'containers/billing/queries/queryTree';
import { _domainQuery } from "containers/domain/queries";
import { _emailQuery } from "containers/email/queries";
import { _HostingQuery } from "containers/hosting/queries";
import { katanaQuery } from "containers/katana/queries/tanstackTree";
import { KatanaQueryV2 } from 'containers/katana/queries/v2/tanstackTree';
import { _AuthQuery } from "containers/login/queries/queryTree";
import { _ms365Query } from "containers/ms365/queries";
import { _orderingFormQuery } from "containers/orderingForm/queries";
import { _ServicesQuery } from "containers/services/queries/tanstackTree";
import { _sslQuery } from 'containers/ssl/queries/queryTree';
import { initialiseQueryClient } from "store/queryClient";
import { _ventraIpWordpressSupportCentreQuery } from "utilities/api/ventraip-website-wp-support-centre/queries/tanstackTree";
import { handleDefaultErrorNotification } from "utilities/methods/commonActions/handleDefaultErrorNotification";
import { handleDefaultSuccessNotification } from "utilities/methods/commonActions/handleDefaultSuccessNotification";
import { _useLazyQuery } from "utilities/methods/queries/useLazyQuery";
import { _usePopupMutation } from "utilities/methods/queries/usePopupMutation";
import { initializeNXQuery } from "utilities/query";

initialiseQueryClient({
    onError: (error) => handleDefaultErrorNotification(error),
    onSuccess: handleDefaultSuccessNotification
});

initializeNXQuery({
    account: _AccountQuery,
    billing: _billingQuery,
    domain: _domainQuery,
    ssl: _sslQuery,
    auth: _AuthQuery,
    email: _emailQuery,
    hosting: _HostingQuery,
    ms365: _ms365Query,
    katana: Object.assign(katanaQuery, {
        v2: KatanaQueryV2,
    }),
    services: _ServicesQuery,
    supportCentre: _ventraIpWordpressSupportCentreQuery,
    orderingForm: _orderingFormQuery,
    Utils: {
        usePopupMutation: _usePopupMutation,
        useLazyQuery: _useLazyQuery,
    },
});
