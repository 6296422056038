/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_Staff.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const StaffTag = () => (
    <div className="staff__notification">
        <PhosphorIcons.MaskHappy white size={28} />
        <div className="text">You are logged in as a staff member</div>
    </div>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default StaffTag;
