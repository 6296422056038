/** @warn this must be the first import in the file */
import 'core-js/features/promise/all-settled';
import 'core-js/features/string/replace-all';
import 'core-js/stable';
import 'utilities/initialization/preload';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { interceptInvalidToken } from 'App/action';
import { setAxiosDefaults as initializeAxiosDefaults } from 'App/setAxiosDefaults';
import CustomErrorBoundary from 'components/Errors/ErrorBoundary';
import { NXQueryProvider } from 'components/NXQueryProvider';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
    initializeLazyLoadHandler,
    initializePolyfillHandler,
    initializeReactGA,
    initializeRouteChangeHandler,
    initializeSentry,
} from 'router/interceptors';
import { RouterContextProvider } from 'router/provider';
import { router } from 'router/router';
import { queryClient } from 'store/queryClient';
import { ViewportManager } from 'utilities/classes/viewportManager/viewportManager';
import { interceptorHandler as initializeInterceptorHandlers } from 'utilities/methods/interceptorHandler';
import { NXQuery } from 'utilities/query';
import store from './store/store';

/**********************************************************************************************************
 *   APPLICATION START
 **********************************************************************************************************/
initializeSentry();
initializeAxiosDefaults();
initializeRouteChangeHandler();
initializeInterceptorHandlers();
initializeLazyLoadHandler();
initializePolyfillHandler();
interceptInvalidToken(router);
initializeReactGA();
ViewportManager.registerEventListeners();

// Note, the app is not being rendered in "strict mode"
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <Provider store={store} stabilityCheck="never">
        <CustomErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <NXQueryProvider queryTree={NXQuery}>
                    <RouterContextProvider />
                    <ReactQueryDevtools initialIsOpen={false} />
                </NXQueryProvider>
            </QueryClientProvider>
        </CustomErrorBoundary>
    </Provider>
);
