import Anchor from 'components/Anchor';
import { OverlayInvoiceContent } from 'components/Lightboxes/OverlayLightbox/Components/invoice/overlayInvoiceContent';
import RequestLoader from 'components/Loaders/Request';
import Text from 'components/Utils/Text';
import { handleInvalidateOptimisticSingleInvoiceQueryData } from 'containers/billing/queries/invoice/methods';
import { useGetFilteredAndPaginatedInvoiceListInfiniteBoilerPlate } from 'containers/billing/queries/invoice/useGetFilteredAndPaginatedInvoiceListInfiniteQuery';
import { useGetInvoiceQuery } from 'containers/billing/queries/invoice/useGetInvoiceQuery';
import { getLoggedInAccountData } from 'containers/dashboard/modules/accounts/methods';
import { NXQuery } from 'utilities/query';
import InvoiceLoadingError from './invoiceLoadingError';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const OverlayInvoice = ({ invoiceID, togglePostPayment, closeInvoice }) => {
    /***** QUERIES *****/
    const { data: login_account_list_data } = NXQuery.auth.accountList.useSelectSuspenseQuery(void 0, ({ data }) => data);
    const { data: app_check_token_data } = NXQuery.auth.login.checkToken.useSelectSuspenseQuery(void 0, ({ data }) => data.attributes);
    const { status: get_invoice_status } = useGetInvoiceQuery(invoiceID);

    /***** DERIVED *****/
    const account = getLoggedInAccountData(login_account_list_data, app_check_token_data);

    /***** FUNCTIONS *****/
    function handleSuccessResponse(paymentData) {
        const email = account?.attributes?.email ?? '';
        const postPayment = {
            status: 'success',
            icon: 'valid',
            title: 'Nice! Payment Successful',
            desc: (
                <Text>
                    You will receive a receipt email at <Anchor href={`mailto:${email}`}>{email}</Anchor> shortly!
                </Text>
            ),
            res: paymentData
        };
        const queryKey = useGetFilteredAndPaginatedInvoiceListInfiniteBoilerPlate.createQueryKey({ page: 1 });
        handleInvalidateOptimisticSingleInvoiceQueryData(queryKey, invoiceID);
        togglePostPayment('success', postPayment);
    }

    /***** RENDER HELPERS *****/
    const isLoading = () => {
        if (get_invoice_status === 'pending' || !app_check_token_data?.account_id || !login_account_list_data) {
            return 'loading';
        }
        return get_invoice_status;
    };

    function renderLoader() {
        return (
            <div className="OverlayInvoice">
                <RequestLoader message="Loading Invoice" />
            </div>
        );
    }

    /***** RENDER *****/
    const loading = isLoading();
    switch (loading) {
        case 'success':
            return <OverlayInvoiceContent invoiceID={invoiceID} handleSuccessResponse={handleSuccessResponse} closeInvoice={closeInvoice} />;
        case 'error':
            return <InvoiceLoadingError />;
        case 'loading':
        default:
            return renderLoader();
    }
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default OverlayInvoice;
